import React, { memo, useLayoutEffect, useRef } from "react";
import './index.scss';

const Messages = memo(({ currentChat, setValues, children, blur, innerRef, isDragging, closeDragging }) => {


  return <div
    className="open-chat__overfield"
    onClick={(e) => currentChat?.isSearchOpen && setValues({ currentChat: { ...currentChat, isSearchOpen: false } })}
    ref={innerRef}
  >
    {isDragging && (
      <div className="drag-overlay">
        <div className="drag-overlay__content">
          <span className="drag-overlay__text">Перетащите файлы сюда</span>
          <button className="drag-overlay__close" onClick={closeDragging}>
            ✕
          </button>
        </div>
      </div>
    )}
    <div className="open-chat__field" style={{ filter: blur ? `grayscale(90%) blur(1px)` : "none" }}>
      {children}
    </div>
  </div>
})

export default Messages;
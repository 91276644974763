import bigDecimal from 'js-big-decimal';
import _ from 'lodash';
import ym from 'react-yandex-metrika';
import { getUrl } from '../api';
import {
  defaultExclude,
  defaultFilter,
  defaultFullFilter,
} from './defaultFilters';
import formatter from 'format-number';

export const withoutExcludes = [
  'prices',
  'endings',
  'squareTotal',
  'squareKitchen',
  'floor',
];
export const typesWithArrayValues = [
  'blocks',
  'districts',
  'subways',
  'builders',
  'banks',
  'availability',
  'payment',
  'purpose',
  'registrations',
  'rooms',
  'roomType',
  'repair',
  'decoration',
  'status',
  'blockClass',
  'balconyType',
  'objectType',
  'balcony',
  'villages',
  'toSea',
  'materials',
  'nearby',
  'features',
  'communications',
  'bathroomTypes',
  'address',
  'client',
  'type',

  'saleType',
  'inFlat',
  'rentType',
  'realtyType',
  'constructionPeriod',
  'section',
  "microDistricts",
  "parkingType",

  "material",
  "projectCountBathrooms",
  "projectCountBedrooms",
  "constructionPeriod",

];
export const typesWithObjectValues = [
  'endings',
  'floor',
  'prices',
  'squareKitchen',
  'squareTotal',
  'squareHouse',
  'squareParcel',
  'floors',
  'endings',
  'issuingKeys',
  'cellHeight',
  'countFloors',
  'price',
];
export const specialValues = [
  'isApartments',
  'isNoFirstFloor',
  'isOnlyHanded',
  'encumbrances',
  'isFirstFloor',
  'assignments',
  'tradeIn',
  'isApartment',
  'isHanded',
];
export const months = [
  'Января',
  'Февраля',
  'Марта',
  'Апреля',
  'Мая',
  'Июня',
  'Июля',
  'Августа',
  'Сентября',
  'Октября',
  'Ноября',
  'Декабря',
];
export const getPricePerMeter = (price, square) => {
  if (!square || !price) return '';
  const parsedPrice = typeof price === "string" ? parseInt(price.replace(/ /gi, "")) : price;
  const parsedSquare = typeof square === "string" ? parseFloat(square.replace(/ /gi, "")) : square;
  if (!parsedPrice || !parsedSquare) return '';
  return parseInt(bigDecimal.divide(parsedPrice, parsedSquare));
};
export const scrollToRef = (ref, padding = 0, node) => {
  if (!ref?.current && !node) {
    return;
  }
  try {
    const elem = ref?.current?.offsetTop ?? node?.offsetTop;
    window.scrollTo({
      top: elem - padding,
      behavior: 'smooth',
    });
  } catch {}
};
export const restoreLastPath = (userHistory) => {
  let host = window.location.host;
  const blackList = [
    '/',
    '/login',
    '/login/',
    '/welcome',
    '/welcome/',
    '/support',
    '/schedule',
    '/register',
    '/register/',
    '/recovery',
    `/recovery/`,
    '/help',
    `/help/`,
    '/register/agency',
    '/register/realtor',
    '/register/agent',
    '/register/builder',
    '/selfemployed',
    `/selfemployed/`,
    '/trial',
    '/trial/',
  ].map((val) => host + val);
  let lastValidHistory = userHistory.find(
    (hist) => !blackList.includes(hist) && hist.includes('panpartner.ru'),
  );
  return lastValidHistory ? lastValidHistory.replace(host, '') : '/';
};
export const serialize = function (obj, prefix) {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === 'object'
          ? serialize(v, k)
          : encodeURIComponent(k) + '=' + encodeURIComponent(v),
      );
    }
  }
  return str.join('&');
};
export const takeExist = (...args) => {
  return args.filter((arg) => !!arg)[0];
};
export const formatMoney = (value, end = '') => {
  if (!value && value !== 0) return '';
  let arr = value.toString().split('');
  arr.reverse();
  for (let i = 3; i < arr.length; i += 4) {
    arr.splice(i, 0, ' ');
  }
  return arr.reverse().join('') + end;
};

export const isFilterOrExclude = (key) => {
  let isFilter = key.search(/filter/);
  let isExclude = key.search(/exclude/);

  if (isFilter !== -1) {
    return 'isFilter';
  }
  if (isExclude !== -1) {
    return 'isExclude';
  }
};

/**
 *  нужна чтобы парсить параметры запроса при обновлении страниц с фильтрами
 * @param {String} str -  строка вида "filter[prices][min]" / filter[prices][]
 * @param {*} value -  значение
 * @param {Array} arrayTypes -  список типов  ["blocks", "rooms" ...], значения которых в store являются массивами
 */
export const parseParams = (str, value, arrayTypes, tempArray = []) => {
  const matchArray = str.match(/([a-z]+)\[([a-zA-Z]+)\]*\[*(.*)\]/);
  let type, subType, key;
  type = matchArray[1];
  subType = matchArray[2];
  key = matchArray[3];
  if (arrayTypes.includes(subType)) {
    tempArray.push(value);
    return {
      [type]: {
        [subType]: Array.from(new Set([...tempArray, value.toString()])),
      },
    };
  }
  if (!type) {
    return;
  }
  if (specialValues.includes(subType)) {
    return { [type]: { [subType]: [{ id: value }] } };
  }
  if (!key || key.length === 0) {
    return { [type]: { [subType]: value } };
  }
  return { [type]: { [subType]: { [key]: value } } };
};
export const getParserTypes = (str) => {
  const matchArray = str.match(/([a-z]+)\[([a-z]+)\]*\[*(.*)\]/);
  if (!matchArray) {
    return;
  }
  return [matchArray[1], matchArray[2]];
};

export const filterToParams = (filter, handleArrays, savedFilterXmlId) => {
  let params = {};
  _.forIn(filter, (value, key) => {
    // key === filter or exclude
    _.forIn(value, (subValue, subKey) => {
      //subKey == "blocks" /"districts" / etc

      //   if (withoutExcludes.includes(subKey) && key == "exclude") {
      //     return;
      //   }

      if (subValue) {
        if (typesWithObjectValues.includes(subKey)) {
          if (subValue.min && subValue.min.length) {
            params[`${key}[${subKey}][min]`] = subValue.min;
          }
          if (subValue.max && subValue.max.length) {
            params[`${key}[${subKey}][max]`] = subValue.max;
          }
        }
        if (typesWithArrayValues.includes(subKey) && subValue.length) {
          params[`${key}[${subKey}]${handleArrays ? '[]' : ''}`] = subValue;
        }
        if (specialValues.includes(subKey) && subValue.length) {
          params[`${key}[${subKey}]`] = subValue[0].id;
        }
        if(!params[`${key}[${subKey}]`] && typeof subValue === "string" && !!subValue){
          params[`${key}[${subKey}]`] = subValue
        }
      }
    });
  });
  _.forIn(params, (value, key) => {
    if (Array.isArray(value)) {
      params[key] = _.uniq(value);
    } else {
      params[key] = value;
    }
  });
  if (savedFilterXmlId) {
    params.savedFilterXmlId = savedFilterXmlId;
  }
  return params;
};
export const filterToParamsConstructor = (filter, isPreview) => {
  let params = {};
  if (isPreview) {
    params.isAdmin = 1;
  }
  _.forIn(filter, (value, key) => {
    // key === filter or exclude
    _.forIn(value, (subValue, subKey) => {
      //subKey == "blocks" /"districts" / etc

      //   if (withoutExcludes.includes(subKey) && key == "exclude") {
      //     return;
      //   }

      if (subValue) {
        if (typesWithObjectValues.includes(subKey)) {
          if (subValue.min) {
            params[`${key}[${subKey}][min]`] = subValue.min;
          }
          if (subValue.max) {
            params[`${key}[${subKey}][max]`] = subValue.max;
          }
        }
        if (typesWithArrayValues.includes(subKey) && !!subValue) {
          params[`${key}[${subKey}]`] = subValue;
        }
        if (specialValues.includes(subKey) && subValue.length) {
          params[`${key}[${subKey}]`] = subValue;
        }
      }
    });
  });
  _.forIn(params, (value, key) => {
    if (Array.isArray(value)) {
      params[key] = _.uniq(value);
    } else {
      params[key] = value;
    }
  });
  return params;
};

export const isEmptyFilterOrExclude = (
  filterOrExclude,
  type,
  defFullFilter,
) => {
  let defaultValues;
  switch (type) {
    case 'filter':
      defaultValues = defFullFilter ? defFullFilter.filter : defaultFilter;
      break;
    case 'exclude':
      defaultValues = defFullFilter ? defFullFilter.exclude : defaultExclude;
      break;
    default:
      defaultValues = {
        ...(defFullFilter ? defFullFilter : defaultFullFilter),
      };
  }

  return _.isEqualWith(
    filterOrExclude,
    defaultValues,
    (objValue, othValue, index) => {
      if (index == 'status' || index == 'tradeIn') {
        return true;
      }
    },
  );
};
export const getRoundedPrice = (price) => {
  if (!price || price === '0') {
    return 'ошибка ';
  }
  return _.floor(price / 1000000, 1);
};

export const formatDate = (date, options) => {
  return new Date(date).toLocaleDateString('ru', options);
};

export const initialSettings = {
  agency_logo: '',
  manager_contacts: '',
  complex_name: '',
  main_photo: '',
  complex_about: '',
  house_characteristics: '',
  layout_picture_ready: '',
  flat_area: '',
  layout_description: '',
  view_for_windows: '',
  layout_plan: '',
  building_on_map: '',
  typical_3d_tour: '',
  complex_advantages: '',
  decoration_description: '',
};

export const settingsToParams = (settings) => {
  let params = {};

  _.forIn(settings, (value, key) => {
    params[`settings[${key}]`] = value;
  });

  return params;
};

export const getSuffix = (count) => {
  let num = count;
  if (count > 9 && count < 15) {
    return 'ов';
  }
  if (count > 9) {
    num = Number(count.toString().substring(count.toString().length - 1));
  }

  if (num === 0 || num > 4) {
    return 'ов';
  }
  if (num > 1 && num < 5) {
    return 'a';
  }
  return '';
};
export const getSuffixUniversal = (config, count) => {
  if (!count) return config.default
  const last = count % 10
  const last2Symb = count.toString().slice(-2)
  for (const [key, value] of Object.entries(config)) {
    const match = key.match(/(\d+)\-(\d+)/)
    if (!match) break;
    const [all, min, max] = match
    if (count <= max && count >= min) return value
    if (last2Symb <= 14 && last2Symb >= 11) return config.default
    if (last <= max && last >= min) return value
  }
  return config.default || ""
}
const getLastSymbol = (count) =>
  count.toString().split('').reverse().join('')[0];

export const getYearsSuffix = (count) => {
  if (count.toString().length > 3) return '';
  const lastSymbol = getLastSymbol(count);
  if (count < 9) {
    if (count == 1) return 'год';
    if (count == 0) return 'лет';
  }
  if (count < 19 && count > 10 && lastSymbol < 5 && lastSymbol > 1)
    return 'лет';
  if (lastSymbol < 5 && lastSymbol > 1) return 'года';
  return 'лет';
};

export const getDaySuffix = (count) => {
  if (count.toString().length > 3) return '';
  const lastSymbol = getLastSymbol(count);
  if (count <= 20 || lastSymbol > 4) {
    if (count == 1) return 'день';
    if (count > 1 && count < 5) return 'дня';
    return 'дней';
  }
  if (count > 20 || lastSymbol <= 4) {
    if (lastSymbol == 1) return 'день';
    if (lastSymbol < 5) return 'дня';
    return 'дней';
  }
};
export const getHoursSuffix = (count) => {
  if (count.toString().length > 3) return '';
  const lastSymbol = getLastSymbol(count);
  if (count <= 20 || lastSymbol > 4) {
    if (count == 1) return 'час';
    if (count > 1 && count < 5) return 'часа';
    return 'часов';
  }
  if (count > 20 || lastSymbol <= 4) {
    if (lastSymbol == 1) return 'час';
    if (lastSymbol < 5) return 'часа';
    return 'часов';
  }
};
export const validateMail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const latMap = {
  '`': 'е',
  q: 'й',
  w: 'ц',
  e: 'у',
  r: 'к',
  t: 'е',
  y: 'н',
  u: 'г',
  i: 'ш',
  o: 'щ',
  p: 'з',
  '[': 'х',
  ']': 'ъ',
  a: 'ф',
  s: 'ы',
  d: 'в',
  f: 'а',
  g: 'п',
  h: 'р',
  j: 'о',
  k: 'л',
  l: 'д',
  ';': 'ж',
  "'": 'э',
  z: 'я',
  x: 'ч',
  c: 'с',
  v: 'м',
  b: 'и',
  n: 'т',
  m: 'ь',
  ',': 'б',
  '.': 'ю',
};
export const searchRuSymbols = (string) => {
  let result = [];
  for (let i = 0, len = string.length; i < len; i++) {
    if (latMap[string[i]]) {
      result[i] = latMap[string[i]];
    } else {
      result[i] = string[i];
    }
  }
  return result.join('');
};

export const getEndingsTitle = (id) => {
  if (!id) return '';
  if(id == 1) return "Проект"
  const quarters = ['I', 'II', 'III', 'IV'];
  let year = id.toString().slice(0, 4);
  let quarter = id.toString().slice(-1);
  if(!quarters[quarter - 1]) return year;
  return quarters[quarter - 1] + ' квартал ' + year;
};
export const media = (type) => {
  const queries = {
    isSmallMobile: '(max-width: 374px)',
    isMobile: '(max-width: 640px)',
    isTablet: '(max-width: 767px)',
    isLaptop: '(max-width: 1279px)',
    isDesktop: '(max-width: 1599px)',

    breakLaptop: '(max-width: 1279px)',
  };
  return window.matchMedia(queries[type]).matches;
};
export const getManager = (original, alternative, clientMode) => {
  return {
    get photo() {
      if (clientMode) {
        return getUrl(alternative?.logo?.src);
      }
      return getUrl(original.photo ? original.photo.src : original.logo?.src);
    },
    get fullName() {
      return clientMode
        ? `${alternative?.name} ${alternative?.lastName}`
        : `${original.name} ${original.lastName}`;
    },
    get phone() {
      return clientMode
        ? alternative?.phone
        : Array.isArray(original.phone)
        ? original.phone[0]
        : original.phone;
    },
    get email() {
      return clientMode
        ? alternative?.email
        : Array.isArray(original.email)
        ? original.email[0]
        : original.email;
    },
    get position() {
      return clientMode ? '' : original.position;
    },
    get telegram() {
      return clientMode ? alternative?.telegram : original.telegram;
    },
  };
};
export const isMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const getPhone = (isLink) => {
  if (window.location.pathname.includes('msk')) {
    return isLink ? 'tel:8(499)3221499' : '8 (499) 322-14-99';
  }
  return isLink ? 'tel:8(812)4261863' : '8 (812) 426-18-63';
};

export const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};
export const runScriptsFromHTML = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  const scripts = div.querySelectorAll('script');
  for (let item of scripts) {
    const script = document.createElement('script');
    script.charset = 'UTF-8';
    if (item.src) {
      script.src = item.src;
      script.async = true;
    } else {
      script.innerHTML = item.innerHTML;
    }
    try {
      document.body.appendChild(script);
    } catch (error) {}
    item.remove();
  }
  return div.innerHTML;
};

export const downloadLink = (file) => {
  var link = document.createElement('a');
  link.href = file;
  link.download = file;
  link.target = '_blank';
  link.dispatchEvent(new MouseEvent('click'));
};

export const evaulateEndings = (endings, returnArray) => {
  if (!endings) return '';
  if (typeof endings === 'string' && endings.length > 0) return endings;
  const notNullFrom = !!endings.from.quarter && !!endings.from.year;
  const isEqualDates = _.isEqual(endings.from, endings.to);
  let value = `${endings.from.quarter} кв. ${endings.from.year}`;
  let from = `${endings.from.quarter} кв. ${endings.from.year}`;
  let to;
  if (!notNullFrom) {
    value = from = `Есть сданные`;
  }
  if (!isEqualDates) {
    value += ` - ${endings.to.quarter} кв. ${endings.to.year}`;
    to = ` - ${endings.to.quarter} кв. ${endings.to.year}`;
  }
  if (!notNullFrom && isEqualDates) {
    value = from = 'Сдан';
  }

  return returnArray ? _.compact([from, to]) : value;
};

const getEndingsTitles = (endings) => {
  return {
    min: getEndingsTitle(endings?.min),
    max: getEndingsTitle(endings?.max),
  };
};

const lsfFunctions = {
  status: (stat = []) => _.uniq([1, ...stat]),
};
// TO DO: использовать это для карточки ЖК , #оптимизация
export const getLocalSavedFilter = (
  type,
  id,
  fields = [],
  defaultFields = [],
) => {
  let localSavedFilter = JSON.parse(localStorage.getItem(type));
  const lsf = localSavedFilter?.[id];
  let resultFilter = {};
  const getDefaultValue = (field) => {
    return typesWithObjectValues.includes(field) ? { min: '', max: '' } : [];
  };

  fields.forEach((field) => {
    let defaultValues = getDefaultValue(field);
    if (!lsf) {
      resultFilter[field] = lsfFunctions[field]
        ? lsfFunctions[field](lsf?.[field])
        : defaultValues;
    } else {
      resultFilter[field] = lsfFunctions[field]
        ? lsfFunctions[field](lsf[field])
        : lsf[field];
    }
  });
  defaultFields.forEach((field) => {
    let defaultValues = getDefaultValue(field);
    resultFilter[field] = defaultValues;
  });
  return resultFilter;
};

export const iterateAsync = async (array, asyncCallback) => {
  const promises = array.map(asyncCallback);
  return await Promise.all(promises);
};

export const parseDate = (string = '', combine = false, standartMethods) => {
  let date = new Date(string);
  const hours = standartMethods ? date.getHours() : date.getUTCHours();
  const minutes = standartMethods ? date.getMinutes() : date.getUTCMinutes();
  const days = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const getValue = (val) => (val < 10 ? `0${val}` : val);
  if (combine) {
    return [
      `${getValue(days)}.${getValue(month + 1)}.${year}`,
      `${getValue(hours)}:${getValue(minutes)}`,
    ];
  }
  return {
    hours,
    minutes,
    days,
    month,
    year,
    getValue,
    formattedTime: `${getValue(hours)}:${getValue(minutes)}`,
  };
};

export const getFieldName = (code, formSettings, isMulti) => {
  if (!formSettings) return;
  for (let setting of formSettings) {
    if (setting.code === code) {
      if (isMulti) {
        return [...setting.items];
      }
      return setting.fieldName
        ? setting.fieldName
        : setting.items?.[0]?.fieldName;
    }
  }
  return '';
};

const ruKeys = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'e',
  ж: 'j',
  з: 'z',
  и: 'i',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'h',
  ц: 'c',
  ч: 'ch',
  ш: 'sh',
  щ: 'shch',
  ы: 'y',
  э: 'e',
  ю: 'u',
  я: 'ya',
  ' ': '-',
  ъ: '',
  ь: '',
  '.': '',
  '-': '',
  й: 'i',
};

export const toTranslit = (word) =>
  word
    .split('')
    .map((letter) => {
      const lowLetter = letter.toLowerCase();
      const symb = ruKeys[lowLetter] ?? letter;
      return lowLetter === letter ? symb : symb.toUpperCase();
    })
    .join('');

export const removeBodyScroll = () => document.body.classList.add('no-scroll');

export const returnBodyScroll = () =>
  document.body.classList.remove('no-scroll');

const ua = window.navigator.userAgent;
const iOS = /iPad/i.test(ua) || /iPhone/i.test(ua);
const webkit = /WebKit/i.test(ua);
export const iOSSafari =
  iOS && webkit && !/CriOS/i.test(ua) && !/OPiOS/i.test(ua);

export const objectFromSearchParams = () => {
  const paramsEntries = new URLSearchParams(window.location.search).entries();
  const result = {};
  for (const [key, value] of paramsEntries) {
    result[key] = value;
  }
  return result;
};

export const formatMs = (ms) => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);
  return {
    days,
    hours,
    minutes,
    sec,
  };
};

export const sendConstructorEvent = (eventName, data, isLead) => {
  if (window.fbq && isLead) {
    window.fbq('track', 'Lead');
  }
  if (window.VK) {
    window.VK.Retargeting.Event(eventName);
  }
  try {
    ym('reachGoal', eventName);
  } catch (error) {}
};
export const makeComa = (v, i, a, toLowerNext, beforeNext = " ") => {
  if (!v) return null;
  if (i !== a.length - 1) return `${toLowerNext && i !== 0  ? v.toLowerCase() : v},${beforeNext}`;
  return toLowerNext && i !== 0 ? v.toLowerCase() : v;
};

export const formattedLocaleDate = (date, withTime) => {
  if (!date) return '';
  const options = {
    day: 'numeric',
    month: 'long',
    ...(withTime ? { hour: '2-digit', minute: '2-digit' } : []),
  };
  return new Date(date.replace('Z', '')).toLocaleString('ru', options);
};

export const appendFiles = (files, formData, ident) => {
  for (const file of files) {
    if (file.fileObj) formData.append(ident, file.fileObj, file.name ?? file.fileName ?? file.fileObj?.name);
  }
  return formData;
};

export const imageTypes = [
  'image/jpeg',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/gif',
];

export const hexToRgb = (hex) => {
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return r + ',' + g + ',' + b;
};

export const toFormData = (object, prefix, withZeros, emptyValues) => {
  if(!object || object instanceof FormData) return object;
  const formData = new FormData();
  for (const [key, values] of Object.entries(object)) {
    if (values === null || values === undefined) continue;
    if (Array.isArray(values)) {
      for (const value of values) {
        (value || (withZeros && value === 0) || emptyValues) &&
          formData.append(prefix ? `${prefix}[${key}][]` : `${key}[]`, value);
      }
      continue;
    }
    if (typeof values === 'object') {
      for (const [subKey, subValue] of Object.entries(values)) {
        (subValue || (withZeros && subValue === 0)) &&
          formData.append(
            prefix ? `${prefix}[${key}][${subKey}]` : `${key}[${subKey}]`,
            subValue,
          );
      }
      continue;
    }
    (values || (withZeros && values === 0)  || emptyValues) && formData.append(prefix ? `${prefix}[${key}]` : key, values);
  }
  return formData;
};
export const combineFormData = (a, b) => {
  for (const values of b.entries()) a.append(...values);
  return a;
};

export const applyToFormData = (formData, object, prefix) => {
  if (!formData || !object) return formData;
  for (const [key, value] of Object.entries(object)) {
    formData.append(prefix? `${prefix}[${key}]`: key, value);
  }
  return formData;
};

export const objectsArrayToFormData = (arr = [], prefix, form) => {
  if (!arr?.length) return;
  const formData = form ?? new FormData();
  for (let i = 0, len = arr.length; i < len; i++) {
    const element = arr[i];
    if (typeof element !== "object") continue;
    for (const [key, value] of Object.entries(element)) {
      formData.append(`${prefix}[${i}][${key}]`, value);
    }
  }
  return formData;
};

export const searchInFieldsByString = (
  target = [],
  config = {},
  search = '',
) => {
  if (!target.length || !config || !search.replace(/ /g, '')) return target;
  const result = [];
  for (const item of target) {
    for (const [configKey, transformer] of Object.entries(config)) {
      let value = item[configKey];
      value = Array.isArray(value)? value.toString(): value;
      if (!value || typeof value !== 'string') continue;
      const sanitizedSearch = search
        .replace(transformer ? transformer : / /g, '')
        .toLowerCase();
      value = value.replace(transformer ? transformer : / /g, '').toLowerCase();
      (value.includes(sanitizedSearch) || sanitizedSearch.includes(value)) &&
        result.push(item);
    }
  }
  return _.uniq(result);
};

export const generateConstructorMarkdown = (text, color) => {
  if (!text) return '';
  const open = `<span style="color: ${color};">`;
  const close = `</span>`;
  return text.replace(/\[/g, open).replace(/\]/g, close);
};

export const sendVKEvent = (eventName) =>
  window.VK && window.VK.Goal(eventName);

export const defineSuffix = (config, count) => {
  if (!count) return config.default;
  const last = count % 10;
  const last2Symb = count.toString().slice(-2);
  for (const [key, value] of Object.entries(config)) {
    const match = key.match(/(\d+)\-(\d+)/);
    if (!match) break;
    const [all, min, max] = match;
    if (count <= max && count >= min) return value;
    if (last2Symb <= 14 && last2Symb >= 11) return config.default;
    if (last <= max && last >= min) return value;
  }
  return config.default || '';
};

export const priceFormat = (price) => {
  return formatter({ integerSeparator: ' ', suffix: ' р.' })(price);
};

export const compareWords = (w1 = '', w2 = ' ', enableTranslit = true) =>
  w1
    .toLowerCase()
    .replace(/ё/giu, 'е')
    .includes(w2.toLowerCase().replace(/ё/giu, 'е')) ||
  (enableTranslit
    ? searchRuSymbols(w1.toLowerCase()).includes(
        searchRuSymbols(w2.toLowerCase()),
      )
    : false);

export const getYoutubeId = (url) => {
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[1].length == 11 ? match[1] : false;
};

export const objectFlagsToBinary = (object = {}, flags = []) => {
  const result = {};
  Object.entries(object).forEach(([key, val]) => {
    result[key] = val;
    if ((flags.includes(key) || !flags.length) && typeof val === "boolean") result[key] = val ? 1 : 0;
  });
  return result;
};

export const renderDate = date => {
  if (!date) return ""
  let time = new Date(date.replace("Z", "")).toLocaleDateString("ru", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  })
  return time.replace('г.,', ' в')
}

export const textToHTML = str => {
  if(!str) return "";
  const div = document.createElement("div")
  div.innerHTML = str;
  return div.textContent
};

export const modyficateKeys = (object, suffix, prefix = "") => {
  const result = {};
  Object.entries(object).forEach(([key, value]) => {
    result[`${prefix}${key}${suffix}`] = value
  })
  return result
}

export const objectToQueryParams = (obj = {}) => Object.entries(obj).reduce((acc, [key, value]) => value === 0 || value === false || value ? acc + `${acc ? "&" : "?"}${key}=${value}` : acc, "");

export const renameObjectKeys = (obj, keyMap) => Object.fromEntries(Object.entries(obj).map(([key, value]) => [keyMap[key] || key, value]));

export const filterAndMap = (arr, fn) => {
  const tmp = [];
  for (const item of arr) {
    const value = fn(item);
    value && tmp.push(value);
  }
  return tmp;
}
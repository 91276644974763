import { filter, uniq, uniqBy } from "lodash";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { DebounceInput } from "react-debounce-input";
import useDebounceState from "../../hooks/useDebounceState";
import { appendFiles, makeComa, months, parseDate, toFormData } from "../../utils";
import SearchInp from "../core/SearchInput";
import StatelessTabs from "../core/Tabs/StatelessTabs";
import ErrorPlug from "../ErrorBoundry/ErrorPlug";
import {
  ChatHeaderMoreIcon,
  ChatHeaderSearchIcon,
  ChatSearchBottomArrowIcon,
  ChatSearchTopArrowIcon
} from "../Public/markup/icons";
import { errorToast } from "../toasts";
import ChatInfo from './ChatInfo';
import ChatItem from "./ChatItem";
import Message from "./ChatMessage";
import { ChatBtn, ChatFooter, CustomSelect, extractMesages } from './Components';
import './index.scss';
import Messages from "./Messages";
import { ChatProvider, useChatContext } from "./ChatContextApi";
import { PhotoPopup } from "../Viewer/PhotoSlider";


const scrollToDown = () => document.querySelector(".open-chat__overfield")?.scrollTo?.(0, 99999);

const Content = memo(props => {

  return <ChatProvider {...props}>
    <Chat />
  </ChatProvider>

})

export default Content;


const Chat = (params) => {
  const { // все дефолтные значения и методы определенны в ChatContextApi.js
    // то что даёт useApiManager 
    getChannels,
    getMessages,
    getChannelInfo,

    sendMessage,
    readMessage,
    dialogWriting,
    deleteMessage,
    updateMessage,
    setUserStatus,
    addFile,

    state,
    setValue,
    reset,
    setValues,
    dispatch,
    api,
    stateRef,



    getFiles,
    searchMessage,
    getPullConfig,
    deleteUser,
    addFiles,


    // useFileManager
    uploadFiles,
    deleteFile,
    files,
    setFiles,

    // рефы 
    pullClientRef,
    typingTimeouts,
    observerRef,
    inputRef,


    chatUser,
    chatRequestId,
    getLastMessage,
    handleEdit,
    deleteMsg,
    saveEdit,
    readMessages,

    chatList,
    messagesList,
    tabs,

    closeAndReset
  } = useChatContext()

  const [isAnimating, setIsAnimating] = useState(false);

  const {
    activeTab,
    isChatOpen,
    currentChat,
    showChatInfo,
    loadingState,
    user,
    members,
    streams,
    messagesDump,
    queueId,
    lastEventId,
    searchValue,
    chatSearchValue,
    searchResult,
    chatSearchResult,
    presences,
    scrollMessage,
    filterMessages,
    showAddList,
    selectedChats,
    forwardedMessage,
    reply,
    allMessages,
    editingMessages,
    pullConfig,
    userId,


    gallery,
    galleryIndex,
    isDragging
  } = state;

  const chatMessagesRef = useRef(null);

  const scrollToMessage = ({ currentChat, id }) => {

    if (!id || !chatMessagesRef.current) return

    if (currentChat) setValue("currentChat", currentChat)

    const messageElem = document.getElementById(id);

    chatMessagesRef.current.scrollTo({
      top: chatSearchValue ? messageElem?.offsetTop - 190 : messageElem?.offsetTop,
    });
  }

  const [readedMessages, setReadedMessages, setDebouncedValues] = useDebounceState({
    setState: (prev, ...args) => (uniq([...prev, +args[0].id.replace("message-", "")])),
    fn: (message, prev) => readMessages(uniq([...prev, +message.id.replace("message-", "")])),
    defaultState: [],
  })

  const send = (messageContent, payload) => {

    const content = typeof messageContent === "string" ? messageContent : messagesDump[currentChat?.requestID];

    if ((!content && !files.length) || !currentChat) return;

    const tmpMessage = {
      status: "sending",
      id: Date.now(),
      author_id: userId,
      date: new Date().toISOString(),
      text: content,
      isSystem: false,
      replaces: [],
      uuid: null,
      forward: null,
      params: [],
      viewedByOthers: false,
      unread: false,
      viewed: true,
      attach: payload?.attach,
      author: chatUser,

      ...(payload?.message ? payload.message : []),
      chat_id: payload?.chat_id || currentChat.chat_id,
    }
    setValues(prevState => ({
      ...prevState,
      allMessages: [...prevState.allMessages, tmpMessage],
      currentChat: { ...prevState.currentChat, message: tmpMessage },
      messagesDump: { ...prevState.messagesDump, [currentChat?.requestID]: "" },
      reply: null,
      selectedChats: [],
      forwardedMessage: null,
    }));

    setTimeout(() => scrollToDown(), 120);

    const dialogId = payload?.chat_id ? payload?.chat_id : files?.length ? currentChat.chat_id : currentChat.id || currentChat.chat_id;

    const formData = toFormData({
      dialogId,
      ...(payload?.forwardMessages ? [] : { message: content }),
      ...(payload?.isReply ? { text: content } : []),
      forwardMessages: payload?.forwardMessages || [],
    })

    if (files?.length) {
      appendFiles(files, formData, "file[]")
      setFiles([])
    }

    return files?.length ? addFiles(formData) : sendMessage(formData)
      .then(({ resp }) => {
        const newMessageId = resp?.result?.id ?? Object.values(resp.result?.uuidMap || {})?.[0]
        if (newMessageId) {
          setValues(prevState => ({
            ...prevState,
            allMessages: uniqBy(prevState.allMessages.map((msg) =>
              msg.id === tmpMessage.id ? { ...msg, id: newMessageId, status: "sent" } : msg
            ), "id"),
          }));
        }
        return resp;
      });

  };

  const replyMessage = () => {

    if (!reply) return

    const requestID = `sendMessage-${Date.now()}`

    send(messagesDump[currentChat?.requestID], { attach: reply?.attach, forwardMessages: [reply.id], requestID, isReply: true })

  };

  const forwardMessage = () => {

    if (!selectedChats?.length) return setValues(prevState => ({ ...prevState, selectedChats: [], forwardedMessage: null }));

    if (!forwardedMessage) return errorToast("Выберите сообщение для пересылки");

    if (!currentChat) return errorToast("Выберите чат для пересылки");

    selectedChats.forEach(chat => {
      send(" ", { attach: reply?.attach, forwardMessages: [forwardedMessage.id], chat_id: chat.chat_id })
    })

  }

  const saveMessage = message => {
    send(" ", { message, forwardMessages: [message.id], chat_id: chatUser?.id })
  }

  const searchMessages = (searchValue, type) => {
    setValues(prevState => ({
      ...(type === "chat" ? { chatSearchValue: searchValue } : { searchValue }),
      ...(searchValue
        ? {}
        : {
          isSearchOpen: false,
          chatSearchResult: false,
          searchResult: false,
        }),
    }));

    if (!searchValue) return;

    const updateSearchResults = (messages) => messages.map(message => ({
      ...message,
      match_content: message.text.replace(
        new RegExp(`(${searchValue})`, "gi"),
        "<mark>$1</mark>"
      ),
    }));

    if (type === "chat") {
      searchMessage({
        dialogId: currentChat.id || currentChat.chat_id,
        "filter[searchMessage]": searchValue,
      }).then(({ resp }) => {
        if (!resp?.result?.messages) return;
        setValues(prevState => ({
          ...prevState,
          chatSearchResult: updateSearchResults(resp.result.messages),
        }));
      });
      return;
    }

    const members = state.members || [];
    const searchResults = members.filter(({ title = "", description = "" }) =>
      [title, description].some(field =>
        field.toLowerCase().includes(searchValue.toLowerCase())
      )
    ).map(member => ({
      ...member,
      match_content: [member.title, member.description]
        .filter(Boolean)
        .map(field =>
          field.replace(
            new RegExp(`(${searchValue})`, "gi"),
            "<mark>$1</mark>"
          )
        )
        .join(" "),
    }));

    setValues(prevState => ({
      ...prevState,
      searchResult: searchResults,
    }));
  };

  useEffect(() => {
    if (!chatRequestId || loadingState.includes(`getMessages-${chatRequestId}`)) return;

    getMessages({ dialogId: currentChat.id || currentChat.chat_id }, `getMessages-${chatRequestId}`).then(resp => {
      setValues(prevState => extractMesages(resp, { requestID: chatRequestId }, stateRef.current));
      return Promise.resolve()
    }).then(() => scrollMessage ? scrollToMessage({ id: scrollMessage }) : scrollToDown());

    const callback = entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) setReadedMessages(entry.target);
      });
    };

    observerRef.current = new IntersectionObserver(callback, {
      root: document.querySelector(".open-chat__overfield"),
      rootMargin: "0px",
      threshold: 1.0,
    });
  }, [chatRequestId]);

  useEffect(() => { // проверить, изначальная логика
    if (!isChatOpen) {
      const timer = setTimeout(() => setIsAnimating(false), 700)
      return () => clearTimeout(timer)
    }
    setUserStatus({ status: "online" });
    setIsAnimating(true);
  }, [isChatOpen])

  const handleClickSendButton = () => {

    if (reply) return replyMessage();

    if (editingMessages[currentChat?.requestID]) return saveEdit();

    return send();

  }

  const handleDrop = useCallback(e => {
    e.preventDefault();
    setValue("isDragging", false);
    const files = Array.from(e.dataTransfer.files);
    uploadFiles({ target: { files } });
  }, [uploadFiles]);

  const handleDragOver = useCallback(e => {
    e.preventDefault();
    if (isDragging) return
    setValue("isDragging", true);
  }, []);

  const handleDragLeave = useCallback(() => {
    if (!isDragging) return
    setValue("isDragging", false);
  }, []);

  const showChatWindow = isChatOpen || isAnimating;

  return (
    <>
      {showChatWindow && <div className={`overchat ${isChatOpen ? 'active' : 'hide'}`}
      >
        <div className={`chat ${isChatOpen ? 'active' : 'hide'}`}>
          <div className="chat__close-chat" onClick={closeAndReset}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L6 6L11 1" stroke="white" stroke-linecap="round" /><path d="M1 11L6 6L11 11" stroke="white" stroke-linecap="round" /></svg>
          </div>
          <div className="chat__left">
            <div className="chat__search">
              <SearchInp
                value={searchValue ?? ""}
                isSearching={loadingState.includes("search-list")}
                placeholder="Поиск"
                searchIcon
                onChange={e => searchMessages(e.target.value, "list")}
                reset={() => searchMessages("", "list")}

                renderInput={props => <DebounceInput
                  minLength={1}
                  debounceTimeout={500}
                  autoComplete="off"
                  className="section-search__inp"
                  {...props}
                />}
              />
            </div>

            <div className="chat__tabs">
              <StatelessTabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={v => setValues({
                  activeTab: v,
                  filterMembers: "all",
                })}
                isInline
              />
            </div>

            <div className="chat__sort">
              <div className="chat__sort-select">
                <CustomSelect
                  options={[
                    { id: "all", label: 'Все' },
                    { id: "read", label: 'Непрочитанные' },
                    { id: "active", label: 'Активные' },
                  ]}
                  onChange={({ id }) => setValue("filterMembers", id)}
                />
              </div>
              {!!forwardedMessage && <div className={'chat__sort-edit chat__sort-edit_sort'} onClick={() => setValues(prevState => ({ ...prevState, selectedChats: [], forwardedMessage: null }))}>
                Отмена
              </div>}
              {!!forwardedMessage && <div className={'chat__sort-edit chat__sort-edit_sort'} onClick={forwardMessage}>Готово</div>}
            </div>
            {loadingState.includes("search-list") && <div className="chat__empty-text">Поиск...</div>}
            {!!searchValue && !chatList?.length && <div className="chat__empty-text">Ничего не найдено</div>}
            {!!searchResult?.length && <ul className="chats-list">
              {searchResult.map(item => (
                <ChatItem
                  userName={item.title}
                  message={item.match_content}
                  onClick={() => setValue("currentChat", item)}
                  key={item.id}
                />
              ))}
            </ul>}
            {!searchResult?.length && <ul className="chats-list">
              {chatList && chatList.map((item) => {

                const shotIsTypingStatus = item.isTyping && !item.requestID.includes("search-") && !item.isSavedMessage && item?.typingUserName

                return <ChatItem
                  userName={item.title}
                  // isFile="Секрктный документ.pdf"
                  lastMessageTime={shotIsTypingStatus ? false : !!item.message?.date && parseDate(item.message.date, false, true).formattedTime}
                  sortered={!!forwardedMessage}
                  selected={selectedChats.some(selected => item.id === selected.id)}
                  selectItem={() => setValues(prevState => ({
                    ...prevState,
                    selectedChats: selectedChats.some(selected => item.id === selected.id) ? selectedChats.filter(v => v.id !== item.id) : [...selectedChats, item]
                  }))}
                  logo={item.avatar?.url}
                  isActive={(!item.message?.scroll && currentChat?.requestID === item.requestID) || scrollMessage === `message-${item.id}`}
                  message={shotIsTypingStatus ? `${item.typingUserName} пишет...` : item.message?.text}
                  isImage={item.message?.files ? item.message.files.filter(file => file.type === "image").at(-1) : []}
                  images={item.message?.files ? item.message.files.filter(file => file.type === "image") : []}
                  onClick={() => {
                    if (currentChat?.id === item.id) return
                    if (item.requestID.includes("search-") && currentChat?.id === item.id) {
                      setValues(prevState => ({ ...prevState, scrollMessage: item.message?.scroll ? `message-${item.id}` : null, }))
                      return scrollToMessage({ id: `message-${item.id}` })
                    }
                    if (forwardedMessage) return
                    setValues(prevState => ({ ...prevState, currentChat: item, chatSearchResult: false, scrollMessage: item.message?.scroll ? `message-${item.id}` : null, reply: null }))
                  }}
                  key={item.requestID}
                  id={item.id}
                  isOnline={item.type !== "chat" && item.user?.status === "online"}
                  unreadMessages={!!item.messages?.length && item.messages.reduce((acc, current) => current.unread ? acc + 1 : acc, 0)}
                />
              }
              )}

            </ul>}

          </div>
          <div className="chat__right"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}

          >
            {!!currentChat?.user && <div className="open-chat">
              <div className="open-chat__header">
                <div className="open-chat__header-left">
                  <div className="open-chat__user">
                    <div className="open-chat__user-logo">
                      {currentChat.user?.avatar ?
                        <img src={currentChat.user.avatar} style={{ width: 48, height: 48, borderRadius: "50%" }} />

                        :
                        <Avatar
                          name={currentChat.title ?? currentChat.name}
                          size="48"
                          round={true}
                          textSizeRatio={2.857}
                        />
                      }
                      {currentChat.type !== "chat" && currentChat.user.status === "online" && <div className="open-chat__user-status open-chat__user-status_active" />}
                    </div>
                    <div className="open-chat__user-info">
                      <p className="open-chat__user-name">{currentChat.title ?? currentChat.name}</p>
                      {!!currentChat.user.work_position && <p className="open-chat__user-post">{currentChat.user.work_position}</p>}
                      {!!currentChat.user.last_activity_date && <p className="open-chat__user-post">Заходил{currentChat.user.gender === "M" ? "" : "а"} {parseDate(currentChat.user.last_activity_date, true)?.map?.(makeComa)}</p>}
                      {/* <p className="open-chat__user-post">Ваш личный менеджер</p> */}
                    </div>
                  </div>
                </div>
                <div className="open-chat__header-right">
                  <span className={`open-chat__search-btn ${currentChat?.isSearchOpen ? 'open-chat__search-btn_active' : ''}`}
                    onClick={() => {
                      setValues(prevState => ({
                        ...prevState,
                        currentChat: { ...prevState.currentChat, isSearchOpen: !prevState.currentChat.isSearchOpen }
                      }))
                      if (chatSearchValue) searchMessages(chatSearchValue, "chat")
                    }}>
                    <ChatHeaderSearchIcon />
                  </span>
                  <span className="open-chat__header-actionbtn" onClick={() => {
                    setValues(prevState => ({
                      ...prevState,
                      showChatInfo: true,
                      currentChat: { ...prevState.currentChat, isSearchOpen: false }
                    }))
                  }}>
                    <ChatHeaderMoreIcon />
                  </span>
                </div>
              </div>

              {currentChat?.isSearchOpen &&
                <div className="open-chat__search">
                  <div className="open-chat__search-area">
                    <div className="open-chat__search-arrows">
                      <span><ChatSearchBottomArrowIcon /></span>
                      <span><ChatSearchTopArrowIcon /></span>
                    </div>
                    <div className="open-chat__search-inp">
                      <SearchInp
                        value={chatSearchValue ?? ""}
                        isSearching={loadingState.includes("search-chat")}
                        placeholder="Поиск"
                        searchIcon
                        onChange={e => searchMessages(e.target.value, "chat")}
                        reset={() => searchMessages("", "chat")}

                        renderInput={props => <DebounceInput
                          minLength={1}
                          debounceTimeout={500}
                          autoComplete="off"
                          className="section-search__inp"
                          {...props}
                        />}
                      />
                    </div>
                    {!!chatSearchResult?.length && searchValue !== '' &&
                      <div className="open-chat__search-count">{chatSearchResult?.length}</div>
                    }

                  </div>

                  {!!chatSearchResult?.length && <div className="open-chat__search-list">
                    <ul className="chats-list chats-list_search">
                      {chatSearchResult.map(item => {
                        const member = members.find(member => member.id === item.author_id)
                        return <ChatItem
                          userName={member.title ?? "*"}
                          lastMessageTime={!!item.timestamp && parseDate(new Date(+`${item.timestamp}000`), false, true).formattedTime}
                          message={item.match_content}
                          onClick={() => scrollToMessage({ id: `message-${item.id}` })}
                          logo={member.avatar?.url}
                          isActive={scrollMessage === `message-${item.id}`}
                          key={item.id}

                        />
                      })}
                    </ul>
                  </div>}
                </div>
              }
              {!currentChat && <div className="chat__empty-text">Пожалуйста, выберите чат</div>}
              {currentChat && !messagesList?.length && !loadingState.includes(currentChat.requestID) && !loadingState.includes(`getMessages-${currentChat.requestID}`) && <div className="chat__empty-text">Сообщений пока нет</div>}
              {currentChat && !messagesList?.length && loadingState.includes(`getMessages-${currentChat.requestID}`) && <div className="chat__empty-text">Загрузка...</div>}
              <ErrorPlug stopSending={true}>
                {!!messagesList?.length && <Messages

                  currentChat={stateRef.current.currentChat}
                  setValues={setValues}
                  key={currentChat?.user_id}
                  blur={forwardedMessage || isDragging}
                  innerRef={chatMessagesRef}
                  closeDragging={() => setValue("isDragging", false)}
                  isDragging={isDragging}
                >
                  {messagesList.map((message, i) => {
                    const {
                      days,
                      month,
                      year,
                      formattedTime,
                    } = parseDate(new Date(message.date), false, true)

                    const { days: previousMessageDay, month: previousMessageMoth, year: previousMessageYear } = parseDate(messagesList[i === 0 ? i : i - 1].date, false, true)
                    const dateText = ((previousMessageDay != days || month !== previousMessageMoth || year !== previousMessageYear) || i === 0) && `${days} ${months[month].toLowerCase()} ${year}`

                    return <React.Fragment key={message.id}>
                      {dateText && <div className="open-chat__date">{dateText}</div>}
                      {message.author_id === 0 && <div className="open-chat__date">{message.text}</div>}
                      {message.author_id !== 0 && <Message
                        // image={y}
                        time={formattedTime}
                        fromMe={userId === message.authorId}
                        {...message}
                        content={message.text}
                        observer={observerRef.current}
                        deleteMsg={() => deleteMsg(message.id)}
                        forwardMessage={() => setValue("forwardedMessage", message)}
                        replyMessage={() => setValue("reply", message)}
                        scrollToMessage={scrollToMessage}
                        onEditClick={() => handleEdit(message.id, message.text)}
                        isSavedMessage={userId === currentChat?.user?.id}
                        saveMessage={() => saveMessage(message,)}
                      />}
                    </React.Fragment>
                  })}

                </Messages>}
              </ErrorPlug>
              {!!currentChat && <ChatFooter
                state={state}
                stateRef={stateRef}
                setValue={setValue}
                setValues={setValues}
                send={handleClickSendButton}
                addFile={addFile}
                setTyping={dialogWriting}
                disabled={!!forwardedMessage}
                reply={reply}
                inputRef={inputRef}
                uploadFiles={uploadFiles}
                deleteFile={deleteFile}
                files={files}
              />}
            </div>}

            {showChatInfo && <ChatInfo
              close={() => setValue("showChatInfo", false)}
              {...currentChat}
              getChannelInfo={getChannelInfo}
              getFiles={getFiles}
              deleteUser={(userId) => deleteUser({ userId: userId ?? chatUser?.id, dialogId: currentChat.id || currentChat.chat_id })}
              id={currentChat.id || currentChat.chat_id}
            />}

          </div>
        </div>
      </div>}
      {!!members?.length && !isChatOpen && <ChatBtn onClick={() => setValue("isChatOpen", !isChatOpen)} />}
      <PhotoPopup
        items={gallery}
        index={galleryIndex ?? 0}
        open={!!gallery?.length}
        onClose={() => setValues({ gallery: false, galleryIndex: 0 })}
      />
    </>
  )
}
import React, { createContext, memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { pull, set, uniq, uniqBy } from "lodash";
import useApiManager from "../../hooks/useApiManager";
import { appendFiles, makeComa, months, parseDate, toFormData } from "../../utils";
import ErrorPlug from "../ErrorBoundry/ErrorPlug";
import SimplePullClient from "./pullService";
import useFileManager from "../../hooks/useFileManager";


export const ChatProvider = memo(({ children, ...props }) => {

    const apiManagerState = useApiManager(apiMethods, apiConfig);

    const fileManagerState = useFileManager({});

    const pullClientRef = useRef(null);

    const typingTimeouts = useRef({});

    const observerRef = useRef(null);

    const inputRef = useRef(null);

    const {
        getPullConfig,
        updateMessage,
        setUserStatus,
        setValues,
        deleteMessage,
        getChannels,
        readMessage,

        state: {
            members,
            userId,
            currentChat,
            allMessages,
            editingMessages,
            selectedChats,
            forwardedMessage,
            searchValue,
            chatSearchValue,
            searchResult,
            chatSearchResult,
            activeTab,
            pullConfig,
            filterMembers
        },
        stateRef
    } = apiManagerState;


    useEffect(() => {

        if (pullConfig) return;

        getChannels().then((resp) => {
            setValues(prevState => ({ ...prevState, members: resp.resp.result ?? [] }))
        })

        getPullConfig({}).then(({ resp }) => {

            const pullConfig = resp?.pullConfig;

            const userId = Object.values(pullConfig?.publicChannels)?.[0]?.user_id;

            if (!userId) return console.error("Не удалось получить конфиг", pullConfig)

            setValues({
                pullConfig,
                userId,
            });
        })

    }, []);

    const setMemberValues = (dialogId, values) => setValues(prevState => {

        const { members } = prevState;

        const updatedMembers = members.map(member => {

            const condition = member.type === "chat" ? member.id === dialogId : member.chat_id === dialogId;

            if (condition) return { ...member, ...values };

            return member;
        });

        return { ...prevState, members: updatedMembers };

    });

    const setMessage = newMessage => setValues(prevState => {

        const { allMessages, members, currentChat } = prevState;

        const findPrevMessage = allMessages.find(message => message.id === newMessage.id);

        const updatedAllMessages = findPrevMessage ? allMessages.map(msg => msg.id === newMessage.id ? { ...msg, status: "sent", ...newMessage } : msg
        ) : [...allMessages, newMessage];

        const updatedMembers = members.map(member => member.chat_id === newMessage.chatId ? { ...member, message: newMessage } : member)

        return {
            ...prevState,
            allMessages: uniqBy(updatedAllMessages, "id"),
            members: updatedMembers,
            currentChat: currentChat?.chat_id === newMessage.chatId ? { ...currentChat, message: newMessage } : currentChat,
        };
    });

    const commands = { // обработка того, что приходит в pull клиенте (в WebSocket)
        messageChat: parsedMessage => {
            const { text } = parsedMessage;

            const message = text?.params?.message;

            if (!message) return console.log('ПУСТОЕ СООБЩЕНИЕ :>> ', parsedMessage);

            const newMessage = {
                ...message,
                chat_id: message.chatId,
                author_id: message.senderId,
                date: new Date(message.date).toISOString(),
                unread: message.senderId !== userId,
                viewed: message.senderId === userId,
            };

            setMessage(newMessage);

        },

        userStatus: ({ text }) => {

            const { users } = text.params;

            if (!members) return

            setValues(prevState => {
                const updatedMembers = [];
                for (const updatedUserId in users) {
                    for (const member of members) {
                        if (updatedUserId === member.id) updatedMembers.push({ ...member, status: users[updatedUserId].status });
                        updatedMembers.push(member);
                    }
                }
                return { ...prevState, members: updatedMembers };
            });
        },

        startWriting: ({ text }) => {

            const { dialogId, userId, userName } = text.params;
            if (!members) return

            setMemberValues(dialogId, { isTyping: true, typingUserName: userName });

            const typingTimeout = typingTimeouts.current[`${dialogId}-${userId}`];

            if (typingTimeout) clearTimeout(typingTimeouts.current[`${dialogId}-${userId}`]);

            typingTimeouts.current[`${dialogId}-${userId}`] = setTimeout(() => {

                setMemberValues(dialogId, { isTyping: false, typingUserName: userName });

                delete typingTimeouts.current[`${dialogId}-${userId}`];
            }, 5000);
        },

        chatUserAdd: ({ text }) => {
            const { newUsers } = text.params;

            if (!newUsers?.length) return;

            const newMembers = text.params.users.filter(user => text.params.newUsers.includes(user.id));

            setValues(prevState => ({
                ...prevState,
                members: [...prevState.members, ...newMembers],
            }))
        },

        chatUserLeave: ({ text }) => {
            return // вот тут подумать нужно оно вообще или нет
            const testText = {
                "module_id": "im",
                "command": "chatUserLeave",
                "params": {
                    "chatId": 12551,
                    "dialogId": "chat12551",
                    "chatTitle": "Чат по сделке Второй Тестовый",
                    "userId": 3110,
                    "message": "Администратор Битрикс24 исключил test tester из чата",
                    "userCount": 6,
                    "chatExtranet": true
                },
                "extra": {
                    "revision_im_web": 130,
                    "revision_im_mobile": 19,
                    "revision_im_rest": 32,
                    "im_revision": 130,
                    "im_revision_mobile": 19,
                    "is_shared_event": false,
                    "server_time": "2025-04-10T17:59:44+03:00",
                    "server_time_unix": 1744297184.810344,
                    "server_name": "devcrm.panpartner.ru",
                    "revision_web": 19,
                    "revision_mobile": 3
                }
            }

            const { newUsers } = text.params;

            if (!newUsers?.length) return;

            const newMembers = text.params.users.filter(user => text.params.newUsers.includes(user.id));

            setValues(prevState => ({
                ...prevState,
                members: [...prevState.members],
            }))
        },
    };

    useEffect(() => {

        if (!userId || pullClientRef.current) return;

        const pullClient = new SimplePullClient({
            config: pullConfig,
            userId: userId,
            siteId: 'default'
        });

        pullClientRef.current = pullClient;

        pullClient.start();


        const unsubscribe = pullClient.subscribe(parsedMessage => {
            console.log('WSS MESSAGE :>> ', parsedMessage);
            commands[parsedMessage?.text?.command]?.(parsedMessage);
        });

        return () => {
            unsubscribe();
            setUserStatus({ status: "away" });
        };

    }, [userId]);

    const chatUser = useMemo(() => members && userId ? members.find(({ id }) => id === userId) : false, [userId, members]);

    const getLastMessage = (chat, messages = allMessages) => {
        return [...messages]
            .reverse()
            .find(message => message.chat_id === chat.chat_id && (!message.isSystem || message.params?.COMPONENT_ID !== "OwnChatCreationMessage"));
    };

    const messagesList = useMemo(() => {
        if (!stateRef.current.currentChat) return [];
        return stateRef.current.allMessages
            .filter(({ chat_id }) => stateRef.current.currentChat.chat_id === chat_id)
            .map((message) => ({
                ...message,
                id: message.id,
                authorId: message.author_id,
                date: message.date,
                text: message.text,
                unread: message.unread,
                viewed: message.viewed,
                viewedByOthers: message.viewedByOthers,
                params: message.params,
                replaces: message.replaces,
            }))
            .sort((a, b) => new Date(a.date) - new Date(b.date));
    }, [stateRef.current.allMessages, stateRef.current.currentChat]);

    const chatList = useMemo(() => {

        if (!members) return

        if (searchValue && searchResult?.length) {
            return searchResult.map(message => ({
                ...message,
                user: members.find(member => member.id === message.author_id) || {},
                // добавить что к результатам поиска, текущий чат, пользователя?
            }));
        }

        return members.map(item => ({
            ...item,
            messages: item.message ?? [],
            requestID: `members-${item.id}`,
            message: getLastMessage(item, allMessages) || item.message,
        }))
            .filter(item => {
                const showCondition = filterMembers === "all" ||
                    (filterMembers === "unread" && item.message?.unread) ||
                    (filterMembers === "active" && item.active);
                const chatType = item.chatType

                if (chatType === "fixation") return activeTab === 2 && showCondition;

                if (chatType === "deal") return activeTab === 1 && showCondition;

                return activeTab === 0 && chatType === "private" && showCondition;
            })
            .sort((a, b) => new Date(b.message?.date || 0) - new Date(a.message?.date || 0));

    }, [members, allMessages, activeTab, searchResult, filterMembers]);


    const tabs = useMemo(() => {
        if (!members) return [];
        const fixations = members.filter(item => item.chatType === "fixation" && item.active);
        const dealList = members.filter(item => item.chatType === "deal" && item.active);
        const personalList = members.filter(item => item.chatType === "private" && item.active);

        return [
            { title: 'Личные', count: personalList.length },
            { title: 'Сделки', count: dealList.length },
            ...(fixations.length ? [{ title: 'Фиксации', count: fixations.length }] : [])
        ];
    }, [members])

    if (!pullConfig) return null;

    const chatRequestId = currentChat?.requestID;

    const changeMessage = (messageId, newValues = {}, deleteMessage = false) => {
        setValues(prevState => {
            const messageIndex = prevState.allMessages.findIndex(message => message.id === messageId);
            const currentMessage = [...prevState.allMessages][messageIndex];
            if (!currentMessage) return prevState;

            let messages = [...prevState.allMessages];
            messages[messageIndex] = { ...currentMessage, ...newValues };
            if (deleteMessage) messages = messages.filter(message => message.id !== messageId);

            return {
                allMessages: messages,
                currentChat: {
                    ...prevState.currentChat,
                    message: [...messages].reverse().find(message =>
                        message.type === "private" && message.display_recipient.some(rec => rec.id === prevState.currentChat.id)
                    )
                }
            };
        });
    };

    const handleEdit = (messageId, content) => {

        const editedMessage = allMessages.find(message => message.id === messageId);

        setValues(prevState => ({
            ...prevState,
            editingMessages: {
                ...prevState.editingMessages,
                [currentChat?.requestID]: { ...editedMessage, messageId, content, currentChat: { ...prevState.currentChat } },
            },
        }));
    };

    const cancelEdit = () => {
      setValues(prevState => {
        return {
        ...prevState,
        editingMessages: {
          ...prevState.editingMessages,
          [currentChat?.requestID]: null,
        },
      }});
    };

    const deleteMsg = id => {
        deleteMessage({ id }).then(({ resp }) => {
            const deleteResult = resp.deleteResult;
            if (deleteResult?.result === "error") {
                changeMessage(id, { error: deleteResult?.msg });
                return;
            }
            changeMessage(id, {}, true);
        });
    };

    const saveEdit = () => {
        if (!editingMessages[currentChat?.requestID]) return;

        const { text: editedContent, messageId: editingMessageId } = editingMessages[currentChat?.requestID] || {};

        updateMessage({
            dialogId: currentChat.id || currentChat.chat_id,
            id: editingMessageId,
            message: editedContent,
        })
            .then(({ resp }) => {

                if (resp?.result) {
                    setValues(prevState => {
                        const updatedMessages = prevState.allMessages.map(msg =>
                            msg.id === editingMessageId ? { ...msg, text: editedContent, status: "edited" } : msg
                        );
                        const tmpEditing = { ...prevState.editingMessages };
                        delete tmpEditing[currentChat?.requestID];
                        return {
                            ...prevState,
                            allMessages: updatedMessages,
                            currentChat: {
                                ...prevState.currentChat,
                                message: updatedMessages.find(msg => msg.id === editingMessageId),
                            },
                            editingMessages: {
                                ...tmpEditing
                            },
                        };
                    });
                }
            });
    };

    const readMessages = messages => {
        if ((Array.isArray(messages) && !messages?.length) || !currentChat?.id) return;
        messages.forEach(messageId => {
            readMessage({
                dialogId: currentChat.chat_id || currentChat.id,
                messageId,
            }).then(() => {
                setValues(prevState => ({
                    currentChat: {
                        ...prevState.currentChat,
                        messages: prevState.currentChat.messages.map(message =>
                            message.id === messageId
                                ? { ...message, viewed: true, unread: false }
                                : message
                        ),
                    },
                    allMessages: prevState.allMessages.map(message =>
                        message.id === messageId
                            ? { ...message, viewed: true, unread: false }
                            : message
                    ),
                }));
            });
        });
    };

    const closeAndReset = () => {;
        setValues(prevState => ({
            ...prevState,
            isChatOpen: false,
            currentChat: null,
            showChatInfo: false,
            isSearchOpen: false,
            chatSearchResult: false,
            searchResult: false,
        }))
    }

    const openGallery = (gallery, galleryIndex) => setValues(prevState => ({
        ...prevState,
        gallery,
        galleryIndex,
    }))

    const closeGallery = () => setValues(prevState => ({
        ...prevState,
        gallery: false,
        galleryIndex: 0,
    }))

    const provederValue = {
        ...apiManagerState,
        ...fileManagerState,
        chatUser,
        observerRef,
        inputRef,
        chatRequestId,

        changeMessage,
        getLastMessage,
        handleEdit,
        cancelEdit,
        deleteMsg,
        saveEdit,
        readMessages,
        closeAndReset,

        openGallery, // в мобиле скорее всего удалить 2 этих функции, 
        closeGallery, // ну и вообще это не функции чата, лучше бы поднять тогда вверх на уровень app (но не везде он нужен, так что тут вопрос целесообразности)

        messagesList,
        chatList,
        tabs,
        ...props
    }

    return (
        <ErrorPlug stopRerender>
            <ChatContext.Provider value={provederValue}>
                {children}
            </ChatContext.Provider>
        </ErrorPlug>
    );
});

const ChatContext = createContext();

export const useChatContext = () => {

    const context = useContext(ChatContext);

    return context;

};

const apiMethods = [
    { action: "getChannels" },
    { action: "getMessages" },
    { action: "getFiles" },
    { action: "sendMessage" },
    { action: "readMessage" },
    { action: "addFile" },
    { action: "dialogWriting" },
    { action: "deleteMessage" },
    { action: "updateMessage" },
    { action: "addFile" },
    { action: "getChannelInfo" },
    { action: "searchMessage" },
    { action: "getPullConfig" },
    { action: "setUserStatus" },
    { action: "deleteUser" },
    { action: "addFiles" },
]

const apiConfig = {
    defaultPayload: { c: 'panpartner:chat.ajax' },
    initialState: {
        isChatOpen: false,
        activeTab: 0,
        currentChat: null,
        showChatInfo: false,
        loadingState: [],
        messagesDump: {},
        filterMembers: "all",
        showAddList: false,
        selectedChats: [],
        forwardedMessage: null,
        reply: null,
        allMessages: [],
        pullConfig: false,
        editingMessages: {},
        userId: null,
        members: null,

        gallery: false,
        galleryIndex: false
    }
}